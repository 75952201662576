export enum Categories {
    DebtManagement = 8001,
    EstatePlanning = 8002,
    InvestmentPlanning = 8003,
    PersonalInsurance = 8004,
    Superannuation = 8007,
    OtherAdvice = 8011,
    SocialSecurity = 8087,
    RetirementPlanning = 8397
}
