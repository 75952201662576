import { PdfSection, TableRow } from '../entities/pdf-content';
import { Injectable } from '@angular/core';
import { NameValue } from '../entities/name-value';
import { PdfSectionTypes } from '../enums/pdf-section-type';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  static getTableRow(nameValue: NameValue, includeSpouse = false): TableRow {
    const tableRow: TableRow = {
      columns: [
        {
          content: nameValue.name
        },
        {
          content: nameValue.value ?? "-"
        }]
    }

    if (includeSpouse) {
      tableRow.columns.push({
        content: nameValue.spouseValue ?? "-"
      });
    }

    return tableRow;
  }

  static getPDFBreakLineSection(): PdfSection {
    return {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.BreakLine,
      title: "",
      content: {
        tableHeaders: [],
        tableRows: []
      }
    };
  }
}
