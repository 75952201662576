import { AgreementOption } from '../enums/agreement-option';
import { AssetType } from '../enums/asset-type';
import { Categories } from '../enums/category';
import { CustomField } from '../enums/custom-field';
import { HoldingStructure } from '../enums/holding-structure';
import { Injectable } from '@angular/core';
import { InsuranceCoverType } from '../enums/insurance-cover-types';
import { InsuranceProviderDataService } from './insurance-provider-data.service';
import { OtherAdviceTypes } from '../enums/other-advice-type';
import { PaymentFrequencies } from '../enums/payment-frequency';
import { SessionTypes } from '../enums/session-type';
import { StatementOfAdviceTypes } from '../enums/statement-of-advice-type';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  private static readonly maxExamplesToReturn = 10;
  static getNameForId(id: number, masterData: MasterDataItem[]) {
    const desc = masterData.find(x => x.iD === id)?.name;
    if (typeof desc === "undefined") {

      // The purpose of this error message is to help someone reading the error message identify
      // the relevant MasterData list.
      // eslint-disable-next-line no-magic-numbers
      const examples = masterData.slice(0, Math.min(MasterDataService.maxExamplesToReturn, masterData.length))
        .map(x => `{iD: ${x.iD}, name: ${x.name}}`).join(", ");
      const numberOfItems = masterData.length;
      const errorMessage = `Could not find master data item with iD ${id}. The master data item list
        has ${numberOfItems} items. Example items: ${examples}`;
      throw new Error(errorMessage);
    }
    return desc;
  }

  // Use this method when the id is optional.
  static getNameForIdWithDefault(id: number | null | undefined, masterData: MasterDataItem[], defaultName: string | null = "") {
    if (id === null || typeof id === 'undefined') {
      return defaultName;
    }

    return MasterDataService.getNameForId(id, masterData);
  }

  static getIdForName(name: string, items: MasterDataItem[]) {
    const id = items.find(x => x.name === name)?.iD
    if (typeof id === "undefined") {
      throw new Error(`Could not find master data record for name ${name}`);
    }

    return id;
  }

  static getMaritalStatues(): MasterDataItem[] {
    return [
      { iD: -1, name: "Unknown Single" },
      { iD: 1, name: 'Single' },
      { iD: 5, name: 'Divorced' },
      { iD: 6, name: 'Widowed' },
      { iD: 7, name: 'Married' },
      { iD: 8, name: 'Separated' },
      { iD: 9, name: 'Defacto' },
      { iD: 10, name: 'Partnered' }
    ];
  }

  static getJointMaritalStatues(): MasterDataItem[] {
    return [

      { iD: -2, name: "Unknown Relationship" },
      { iD: 2, name: 'Married' },
      { iD: 3, name: 'Defacto' },
      { iD: 4, name: 'Partnered' }
    ];
  }

  static getResidentialStatues(): MasterDataItem[] {
    return [
      { iD: -1, name: 'Unknown' },
      { iD: 1, name: 'Australian Citizen' },
      { iD: 2, name: 'Permanent Resident' },
      { iD: 3, name: 'Foreign Resident' },
      { iD: 4, name: 'Temporary Resident' },
      { iD: 5, name: 'Working Visa/Permit' }
    ];
  }

  static getSalutations(): MasterDataItem[] {
    return [
      { iD: -1, name: 'Unknown' },
      { iD: 1, name: 'Mr' },
      { iD: 2, name: 'Mrs' },
      { iD: 3, name: 'Miss' },
      { iD: 4, name: 'Ms' },
      { iD: 5, name: 'Dr' },
      { iD: 6, name: 'Prof' },
      { iD: 7, name: 'Rev' },
      { iD: 8, name: 'Other' },
      { iD: 9, name: 'Assoc Prof' },
      { iD: 10, name: 'Professor' },
      { iD: 11, name: 'Capt' },
      { iD: 12, name: 'Hon' },
      { iD: 13, name: 'Master' },
      { iD: 14, name: 'Mx' },
      { iD: 15, name: 'Sir' },
      { iD: 16, name: 'Lady' },
      { iD: 17, name: 'FR' }
    ];
  }

  static getGenders(): MasterDataItem[] {
    return [
      { iD: 1, name: 'Male' },
      { iD: 2, name: 'Female' },
      { iD: 3, name: 'Other' }
    ]
  }

  static getAddressStates(): MasterDataItem[] {
    return [
      { iD: -1, name: 'Unknown' },
      { iD: 1, name: 'New South Wales' },
      { iD: 2, name: 'Victoria' },
      { iD: 3, name: 'Western Australia' },
      { iD: 4, name: 'Queensland' },
      { iD: 5, name: 'South Australia' },
      { iD: 6, name: 'Australian Capital Territory' },
      { iD: 7, name: 'Northern Territory' },
      { iD: 8, name: 'Tasmania' }
    ]
  }

  static getAssetTypes(): MasterDataItem[] {
    return [
      { iD: AssetType.CashAccount, name: "Cash Account" },
      { iD: AssetType.ResidentialProperty, name: "Residential Property" },
      { iD: AssetType.AustralianShares, name: "Australian Shares" },
      { iD: AssetType.InternationalShares, name: "International Shares" },
      { iD: AssetType.ManagedFund, name: "Managed Fund" },
      { iD: AssetType.CashTermDeposit, name: "Cash Term Deposit" },
      { iD: AssetType.InvestmentProperty, name: "Investment Property" },
      { iD: AssetType.Other, name: "Other" },
      { iD: AssetType.Holding, name: "Holding" }
    ];
  }

  static getIncomeFrequencies(): MasterDataItemString<string>[] {
    return ["Annually", "Bi-Annually", "Weekly", "Fortnightly", "Monthly", "Quarterly"]
      .map(x => ({ iD: x, name: x }))
  }

  static getHoldingStructures(): MasterDataItem[] {
    return [
      { iD: HoldingStructure.All, name: "All" },
      { iD: HoldingStructure.Annuity, name: "Annuity" },
      { iD: HoldingStructure.Investment, name: "Investment" },
      { iD: HoldingStructure.Pension, name: "Pension" },
      { iD: HoldingStructure.SuperAndPension, name: "Super and Pension" },
      { iD: HoldingStructure.Superannuation, name: "Superannuation" },
      { iD: HoldingStructure.Unknown, name: "Unknown" }];
  }

  static getLoanTypes(): MasterDataItem[] {
    return [
      { iD: 1, name: "Home Loan" },
      { iD: 2, name: "Investment Loan" },
      { iD: 3, name: "Margin Loan" },
      { iD: 4, name: "Personal" },
      { iD: 5, name: "Credit Card" },
      { iD: 6, name: "Other Loan" },
      { iD: 7, name: "Help Debt" }];
  }

  static getLoanRepaymentTypes(): MasterDataItem[] {
    return [
      { iD: 1, name: "Principal and Interest" },
      { iD: 2, name: "Interest Only" },
      { iD: 3, name: "Line of Credit" },
    ];
  }

  static getInterestRateTypes(): MasterDataItem[] {
    return [
      { iD: 1, name: "Fixed Interest" },
      { iD: 2, name: "Variable Interest" },
    ];
  }

  static getPaymentFrequencyTypes(): MasterDataItem[] {
    return [
      { iD: PaymentFrequencies.Annually, name: "Annually" },
      { iD: PaymentFrequencies.BiAnnually, name: "Bi-Annually" },
      { iD: PaymentFrequencies.Quarterly, name: "Quarterly" },
      { iD: PaymentFrequencies.Monthly, name: "Monthly" },
      { iD: PaymentFrequencies.TwiceMonthly, name: "Twice Monthly" },
      { iD: PaymentFrequencies.Fortnightly, name: "Fortnightly" },
      { iD: PaymentFrequencies.Weekly, name: "Weekly" },
    ];
  }

  static getPersonalAssetTypes(): MasterDataItem[] {
    return [
      { iD: 1, name: "Residential Property" },
      { iD: 2, name: "Contents" },
      { iD: 3, name: "Other" },
      { iD: 4, name: "Motor Vehicle" },
    ];
  }

  static getEmploymentTypes(): MasterDataItem[] {
    return [
      { iD: -1, name: 'Unknown' },
      { iD: 1, name: 'Full time' },
      { iD: 2, name: 'Self employed' },
      { iD: 3, name: 'Retired' },
      { iD: 4, name: 'Not employed' },
      { iD: 5, name: 'Home duties' },
      { iD: 6, name: 'Unemployed' },
      { iD: 7, name: 'Part time/Casual' },
      { iD: 8, name: 'Permanent part time' },
      { iD: 9, name: 'Student' },
      { iD: 10, name: 'Contractor' },
      { iD: 11, name: 'Freelance' },
      { iD: 12, name: 'Volunteer' },
      { iD: 13, name: 'Semi retired' },
    ];
  }

  static getRelationshipTypes(): MasterDataItem[] {
    return [
      { iD: 1, name: 'Parent', enumName: 'Parent' },
      { iD: 2, name: 'Sibling', enumName: 'Sibling' },
      { iD: 3, name: 'Parent-in-law', enumName: 'ParentInLaw' },
      { iD: 4, name: 'Sibling-in-law', enumName: 'SiblingInLaw' },
      { iD: 5, name: 'Ex partner', enumName: 'ExPartner' },
      { iD: 6, name: 'Uncle/aunt', enumName: 'UncleAunt' },
      { iD: 7, name: 'Cousin', enumName: 'Cousin' },
      { iD: 8, name: 'Grandparent', enumName: 'Grandparent' },
      { iD: 9, name: 'Stepparent', enumName: 'Stepparent' },
      { iD: 10, name: 'Stepsibling', enumName: 'Stepsibling' },
      { iD: 11, name: 'Foster parent', enumName: 'FosterParent' },
      { iD: 13, name: 'Guardian', enumName: 'Guardian' },
      { iD: 14, name: 'Carer', enumName: 'Carer' },
      { iD: 15, name: 'Friend', enumName: 'Friend' },
      { iD: 34, name: 'Medical Power of Attorney', enumName: 'MedicalPowerOfAttorney' },
      { iD: 35, name: 'General Power of Attorney', enumName: 'GeneralPowerOfAttorney' },
      { iD: 36, name: 'Enduring Power of Attorney', enumName: 'EnduringPowerOfAttorney' },
      { iD: 37, name: 'Spouse', enumName: 'Spouse' },
      { iD: 46, name: 'De Facto', enumName: 'DeFacto' }
    ];
  }

  static getInsuranceProviderTypes(): MasterDataItem[] {
    return InsuranceProviderDataService.data;
  }

  static getInsuranceCoverTypes(): MasterDataItem[] {
    return [
      { iD: InsuranceCoverType.Life, name: "Life" },
      { iD: InsuranceCoverType.Tpd, name: "TPD" },
      { iD: InsuranceCoverType.Trauma, name: "Trauma" },
      { iD: InsuranceCoverType.IncomeProtection, name: "Income Protection" },
      { iD: InsuranceCoverType.TpdExtensionToLife, name: "TPD Extension to Life" },
      { iD: InsuranceCoverType.TpdExtensionToTrauma, name: "TPD Extension to Trauma" },
      { iD: InsuranceCoverType.TraumaExtensionToLife, name: "Trauma Extension to Life" },
    ];
  }

  static getObjectiveStatuses(): MasterDataItem[] {
    return [
      { iD: 1, name: 'Current' },
      { iD: 2, name: 'Inactive' },
      { iD: 3, name: 'Achieved' },
      { iD: 4, name: 'Future goal' },
      { iD: 5, name: 'Advice referral' },
      { iD: 6, name: 'Advice completed' }
    ];
  }

  static getTimeframes(): MasterDataItem[] {
    return [
      { iD: 1, name: 'Now' },
      { iD: 2, name: 'Short term' },
      { iD: 3, name: 'Medium term' },
      { iD: 4, name: 'Long term' }
    ];
  }

  static getCategories(): MasterDataItemCategory[] {
    return [
      { iD: Categories.DebtManagement, name: 'Debt Management', commentCustomFieldId: CustomField.DebtManagementComment, excludedAdviceCustomFieldId: CustomField.DebtManagementExcludedAdvice },
      { iD: Categories.EstatePlanning, name: 'Estate Planning', commentCustomFieldId: CustomField.EstatePlanningComment, excludedAdviceCustomFieldId: CustomField.EstatePlanningExcludedAdvice },
      { iD: Categories.InvestmentPlanning, name: 'Investment Planning', commentCustomFieldId: CustomField.InvestmentPlanningComment, excludedAdviceCustomFieldId: CustomField.InvestmentPlanningExcludedAdvice },
      { iD: Categories.PersonalInsurance, name: 'Personal Insurance', commentCustomFieldId: CustomField.PersonalInsuranceComment, excludedAdviceCustomFieldId: CustomField.PersonalInsuranceExcludedAdvice },
      { iD: Categories.Superannuation, name: 'Superannuation', commentCustomFieldId: CustomField.SuperannuationComment, excludedAdviceCustomFieldId: CustomField.SuperannuationExcludedAdvice },
      { iD: Categories.OtherAdvice, name: 'Other Advice', commentCustomFieldId: CustomField.OtherAdviceComment, excludedAdviceCustomFieldId: CustomField.OtherAdviceExcludedAdvice },
      { iD: Categories.SocialSecurity, name: 'Social Security', commentCustomFieldId: CustomField.SocialSecurityComment, excludedAdviceCustomFieldId: CustomField.SocialSecurityExcludedAdvice },
      { iD: Categories.RetirementPlanning, name: 'Retirement Planning', commentCustomFieldId: CustomField.RetirementPlanningComment, excludedAdviceCustomFieldId: CustomField.RetirementPlanningExcludedAdvice }
    ];
  }

  static getSubCategories(): MasterDataItem[] {
    return [
      { iD: 29926, parentID: Categories.PersonalInsurance, name: 'Life' },
      { iD: 29927, parentID: Categories.PersonalInsurance, name: 'Total Permanent Disability' },
      { iD: 29928, parentID: Categories.PersonalInsurance, name: 'Income Protection' },
      { iD: 29929, parentID: Categories.PersonalInsurance, name: 'Trauma' },
      { iD: 29930, parentID: Categories.PersonalInsurance, name: 'Employer Funded Insurance' },
      { iD: 29931, parentID: Categories.PersonalInsurance, name: 'Other' },

      { iD: 29932, parentID: Categories.Superannuation, name: 'Contributions' },
      { iD: 29933, parentID: Categories.Superannuation, name: 'Product' },
      { iD: 29934, parentID: Categories.Superannuation, name: 'SMSF - Insurance' },
      { iD: 29935, parentID: Categories.Superannuation, name: 'SMSF - Other' },
      { iD: 29936, parentID: Categories.Superannuation, name: 'Other' },

      { iD: 29923, parentID: Categories.InvestmentPlanning, name: 'Cash in bank' },
      { iD: 29924, parentID: Categories.InvestmentPlanning, name: 'Direct Shares' },
      { iD: 29925, parentID: Categories.InvestmentPlanning, name: 'Other' },

      { iD: 29938, parentID: Categories.RetirementPlanning, name: 'Income Generation' },
      { iD: 29939, parentID: Categories.RetirementPlanning, name: 'Product' },
      { iD: 29940, parentID: Categories.RetirementPlanning, name: 'Other' },

      { iD: 29921, parentID: Categories.DebtManagement, name: 'Other' },

      { iD: 29937, parentID: Categories.SocialSecurity, name: 'Other' },

      { iD: 29922, parentID: Categories.EstatePlanning, name: 'Other' },

      { iD: 29941, parentID: Categories.OtherAdvice, name: 'Other' },
    ];
  }

  static getOtherAdviceTypes(): MasterDataItem[] {
    return [
      { iD: OtherAdviceTypes.None, name: 'N/A' },
      { iD: OtherAdviceTypes.BusinessInsurance, name: 'Business insurance' },
      { iD: OtherAdviceTypes.SMSF, name: 'SMSF' },
      { iD: OtherAdviceTypes.NonIndividualInvestment, name: 'Non - individual investment' },
    ];
  }

  static getStatementOfAdviceTypes(): MasterDataItem[] {
    return [
      { iD: StatementOfAdviceTypes.StatementOfAdviceComprehensive, name: 'Statement of Advice - Comprehensive' },
      { iD: StatementOfAdviceTypes.StatementOfAdviceFocused, name: 'Statement of Advice - Focused' },
      { iD: StatementOfAdviceTypes.RecordOfAdvice, name: 'Record of Advice' },
    ];
  }

  static getHealthTypes(): MasterDataItem[] {
    return [
      { iD: -1, name: 'Unknown' },
      { iD: 1, name: 'Poor' },
      { iD: 2, name: 'Average' },
      { iD: 3, name: 'Good' },
      { iD: 4, name: 'Excellent' }
    ];
  }

  static getDiscountReferralTypes(): MasterDataItem[] {
    return [
      { iD: 29127, name: "N/A" },
      { iD: 29128, name: "AIA Australia" },
      { iD: 29129, name: "Commonwealth Bank" },
      { iD: 29130, name: "Commonwealth Bank (Digital)" },
      { iD: 29475, name: "Invictus Financial" },
      { iD: 29476, name: "Brokerhouse" },
      { iD: 29477, name: "Chatham House Financial" },
      { iD: 29490, name: "The ItsMy Group" },
      { iD: 29522, name: "Endota Spa" },
      { iD: 29523, name: "Multicultural" },
      { iD: 29719, name: "Yellow Brick Road" },
      { iD: 29721, name: "Australian Finance Group" },
      { iD: 29733, name: "VOW Financial" },
      { iD: 29734, name: "Finsure" },
      { iD: 29735, name: "HESTA" }
    ]
  }

  static getSessionTypes(): MasterDataItem[] {
    return [
      { iD: SessionTypes.NewMeeting, name: 'New meeting' },
      { iD: SessionTypes.ContinueMeeting, name: 'Continue meeting' },
      { iD: SessionTypes.AdviserNotes, name: 'Adviser notes' },
    ];
  }

  static getCentrelinkBenefitTypes(): MasterDataItem[] {
    return [
      { iD: -1, name: "N/A" },
      { iD: 1, name: "Age Pension" },
      { iD: 2, name: "Carer Payment (non taxable)" },
      { iD: 3, name: "Carer Payment (taxable)" },
      { iD: 4, name: "Disability Support Pension" },
      { iD: 5, name: "Family Tax Benefit Part A" },
      { iD: 6, name: "Family Tax Benefit Part B" },
      { iD: 7, name: "Income Support Supplement" },
      { iD: 8, name: "JobSeeker Allowance" },
      { iD: 9, name: "Parenting Allowance" },
      { iD: 10, name: "Partner Allowance (post Sept 2003)" },
      { iD: 11, name: "Partner Allowance (pre Sept 2003)" },
      { iD: 12, name: "Rent Assistance" },
      { iD: 13, name: "Service Pension" },
      { iD: 14, name: "Special Rate Disability Pension (MRCA)" },
      { iD: 15, name: "War Widow(er)s Pension and Orphans Pension" },
      { iD: 16, name: "Mobility Allowance" },
      { iD: 17, name: "Sickness Benefit" },
      { iD: 18, name: "Other" }
    ];
  }

  static getEaAgreementTypes(): MasterDataItem[] {
    return [
      { iD: AgreementOption.IAgree, name: "I Agree" },
      { iD: AgreementOption.IDefer, name: "I Defer" },
    ]
  }
}

export interface MasterDataItem {
  iD: number;
  name: string;
  parentID?: number;
  enumName?: string;
}


export interface MasterDataItemCategory extends MasterDataItem {
  commentCustomFieldId: number;
  excludedAdviceCustomFieldId: number;

}

export interface MasterDataItemSubCategory extends MasterDataItem {
  itemId: number;
}

export interface MasterDataItemString<T extends string> {
  iD: T;
  name: string;
}