export enum CustomField {
    ReceiveCentrelinkPayment = 226,
    HasSeniorHealthCareCard = 228,
    ProvidesCareToAnotherSick = 237,
    DoYouHaveTestamentaryTrust = 218,
    AdviceFeeDiscountArrangement = 33478,
    AdviceFeeDiscountCode = 33473,
    AdviceFeeTier = 33472,
    AdviceFeeDiscountAmount = 33643,
    AdviceFeeNetAmount = 33644,

    DebtManagementComment = 34208,
    EstatePlanningComment = 34210,
    InvestmentPlanningComment = 34212,
    PersonalInsuranceComment = 34214,
    SuperannuationComment = 34216,
    SocialSecurityComment = 34218,
    RetirementPlanningComment = 34220,
    OtherAdviceComment = 34222,

    DebtManagementExcludedAdvice = 34207,
    EstatePlanningExcludedAdvice = 34209,
    InvestmentPlanningExcludedAdvice = 34211,
    PersonalInsuranceExcludedAdvice = 34213,
    SuperannuationExcludedAdvice = 34215,
    SocialSecurityExcludedAdvice = 34217,
    RetirementPlanningExcludedAdvice = 34219,
    OtherAdviceExcludedAdvice = 34221
}
