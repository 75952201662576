import { Injectable } from '@angular/core';
import { MasterDataItem } from './master-data.service';

@Injectable({
    providedIn: 'root'
})
export class InsuranceProviderDataService {

    static readonly data: MasterDataItem[] =
        [
            {
                iD: 1,
                name: "AIA Australia Limited"
            },
            {
                iD: 2,
                name: "Allianz Australia Life Insurance Limited"
            },
            {
                iD: 3,
                name: "AMP Life Limited "
            },
            {
                iD: 4,
                name: "Austock Life Limited"
            },
            {
                iD: 5,
                name: "Australian Friendly Society Ltd"
            },
            {
                iD: 6,
                name: "Australian Scholarships Group Friendly Society Limited"
            },
            {
                iD: 7,
                name: "Australian Unity Investment Bonds Limited"
            },
            {
                iD: 8,
                name: "AXA Australia"
            },
            {
                iD: 9,
                name: "BT Life Limited"
            },
            {
                iD: 10,
                name: "Centuria Life Limited"
            },
            {
                iD: 11,
                name: "CHALLENGER LIFE COMPANY HOLDINGS PTY LTD"
            },
            {
                iD: 12,
                name: "Clearview Life"
            },
            {
                iD: 13,
                name: "Colonial Mutual Life Assurance Society Limited"
            },
            {
                iD: 14,
                name: "Combined Life Insurance Company of Australia Limited"
            },
            {
                iD: 15,
                name: "Commonwealth Life Limited"
            },
            {
                iD: 16,
                name: "CUA Friendly Society Limited"
            },
            {
                iD: 17,
                name: "Cuna Mutual Insurance Society"
            },
            {
                iD: 18,
                name: "Druids Friendly Society Limited"
            },
            {
                iD: 19,
                name: "Foresters Community Finance"
            },
            {
                iD: 20,
                name: "Foresters Friendly Society Ltd"
            },
            {
                iD: 21,
                name: "General Reinsurance Life Australia Ltd"
            },
            {
                iD: 22,
                name: "Global Life Reinsurance Company of Australia Pty Ltd"
            },
            {
                iD: 23,
                name: "Hallmark Life Insurance Company Limited"
            },
            {
                iD: 24,
                name: "Hannover Life Re of Australasia Limited"
            },
            {
                iD: 25,
                name: "Home Owners Friendly Society Ltd"
            },
            {
                iD: 26,
                name: "IOOF Employer Super"
            },
            {
                iD: 27,
                name: "Lifeplan Australia Friendly Society Limited"
            },
            {
                iD: 28,
                name: "Manchester Unity Australia (IOOF WA) Limited"
            },
            {
                iD: 29,
                name: "MBF Life"
            },
            {
                iD: 30,
                name: "MetLife Insurance Limited"
            },
            {
                iD: 31,
                name: "Munich Reinsurance Company of Australia Limited"
            },
            {
                iD: 32,
                name: "Newcastle Friendly Society Limited"
            },
            {
                iD: 33,
                name: "NobleOak Life Limited"
            },
            {
                iD: 34,
                name: "OnePath Life Limited"
            },
            {
                iD: 35,
                name: "Over Fifty Guardian Friendly Society Limited"
            },
            {
                iD: 36,
                name: "Protestant Alliance Friendly Society of NSW Limited"
            },
            {
                iD: 37,
                name: "RGA REINSURANCE COMPANY OF AUSTRALIA LIMITED"
            },
            {
                iD: 38,
                name: "SCOR Global Life Australia Pty Limited"
            },
            {
                iD: 39,
                name: "St Andrews Life Insurance Pty Ltd"
            },
            {
                iD: 40,
                name: "Suncorp Life & Superannuation Ltd"
            },
            {
                iD: 41,
                name: "Sureplan Friendly Society"
            },
            {
                iD: 42,
                name: "Swiss Re Life & Health Australia Limited"
            },
            {
                iD: 43,
                name: "TAL Life Limited"
            },
            {
                iD: 44,
                name: "The National Mutual Life Association of Australasia Limited"
            },
            {
                iD: 45,
                name: "Westpac Life Insurance Services Limited"
            },
            {
                iD: 46,
                name: "Zurich Australia Limited"
            },
            {
                iD: 47,
                name: "Macquarie life"
            },
            {
                iD: 48,
                name: "Comminsure"
            },
            {
                iD: 49,
                name: "MLC Life"
            },
            {
                iD: 50,
                name: "Asteron Life"
            },
            {
                iD: 51,
                name: "Asgard Life"
            },
            {
                iD: 52,
                name: "AGEST"
            },
            {
                iD: 53,
                name: "AIG Australia Limited"
            },
            {
                iD: 54,
                name: "ANZ Staff Super"
            },
            {
                iD: 55,
                name: "AustralianSuper"
            },
            {
                iD: 56,
                name: "Aviva"
            },
            {
                iD: 57,
                name: "Colonial First State"
            },
            {
                iD: 58,
                name: "FutureWise Super"
            },
            {
                iD: 59,
                name: "Health Super"
            },
            {
                iD: 60,
                name: "HESTA Super"
            },
            {
                iD: 61,
                name: "Hostplus"
            },
            {
                iD: 62,
                name: "ING Life Ltd"
            },
            {
                iD: 63,
                name: "Lumley Life"
            },
            {
                iD: 64,
                name: "Mercer"
            },
            {
                iD: 65,
                name: "Aon Hewitt Australia"
            },
            {
                iD: 66,
                name: "CBUS Super"
            },
            {
                iD: 67,
                name: "First State Super"
            },
            {
                iD: 68,
                name: "AC&L Insurance"
            },
            {
                iD: 69,
                name: "MTAA Super"
            },
            {
                iD: 70,
                name: "Qsuper"
            },
            {
                iD: 71,
                name: "REST Industry Super"
            },
            {
                iD: 72,
                name: "TWUSUPER"
            },
            {
                iD: 73,
                name: "VicSuper pty ltd"
            },
            {
                iD: 74,
                name: "Real Insurance"
            },
            {
                iD: 75,
                name: "LUCRF Super Fund"
            },
            {
                iD: 76,
                name: "UniSuper"
            },
            {
                iD: 77,
                name: "Mine Wealth + Wellbeing"
            },
            {
                iD: 78,
                name: "Portfolio Care"
            },
            {
                iD: 79,
                name: "LG Super"
            },
            {
                iD: 80,
                name: "Catholic Super"
            },
            {
                iD: 81,
                name: "Kinetic Super"
            },
            {
                iD: 82,
                name: "ALI Group"
            },
            {
                iD: 83,
                name: "Australia Post Super"
            },
            {
                iD: 84,
                name: "CareSuper"
            },
            {
                iD: 85,
                name: "FirstChoice Employer Super"
            },
            {
                iD: 86,
                name: "Life Insurance Plan"
            },
            {
                iD: 87,
                name: "MIESF"
            },
            {
                iD: 88,
                name: "Nestle"
            },
            {
                iD: 89,
                name: "PCCAP"
            },
            {
                iD: 90,
                name: "Plum Superannuation"
            },
            {
                iD: 91,
                name: "Prime Super"
            },
            {
                iD: 92,
                name: "PSS Super"
            },
            {
                iD: 93,
                name: "Qantas Super Fund"
            },
            {
                iD: 94,
                name: "RBF Super"
            },
            {
                iD: 95,
                name: "Russell Super Solution"
            },
            {
                iD: 96,
                name: "Spectrum Super"
            },
            {
                iD: 97,
                name: "Statewide Super Trust"
            },
            {
                iD: 98,
                name: "Tasplan Super"
            },
            {
                iD: 99,
                name: "NAB"
            },
            {
                iD: 100,
                name: "Netwealth"
            },
            {
                iD: 101,
                name: "SmartSave Super"
            },
            {
                iD: 102,
                name: "AFA"
            },
            {
                iD: 103,
                name: "Man Investments"
            },
            {
                iD: 104,
                name: "AAMI"
            },
            {
                iD: 105,
                name: "ABC Superannuation Fund"
            },
            {
                iD: 106,
                name: "ACE Insurance"
            },
            {
                iD: 107,
                name: "AGCO Australia Superannuation"
            },
            {
                iD: 108,
                name: "American Express"
            },
            {
                iD: 109,
                name: "Anglican National Super"
            },
            {
                iD: 110,
                name: "Auscoal Super"
            },
            {
                iD: 111,
                name: "AustChoice Super"
            },
            {
                iD: 112,
                name: "Bendigo Superannuation"
            },
            {
                iD: 113,
                name: "BHP Superannuation Fund"
            },
            {
                iD: 114,
                name: "Bicycle NSW"
            },
            {
                iD: 115,
                name: "CBA"
            },
            {
                iD: 116,
                name: "CGU Insurance"
            },
            {
                iD: 117,
                name: "Christian Super"
            },
            {
                iD: 118,
                name: "Chubb Insurance"
            },
            {
                iD: 119,
                name: "Citicorp"
            },
            {
                iD: 120,
                name: "Club Plus Super"
            },
            {
                iD: 121,
                name: "Club Super"
            },
            {
                iD: 122,
                name: "Defence Health"
            },
            {
                iD: 123,
                name: "DFRDB (Military Pension)"
            },
            {
                iD: 124,
                name: "Equip Super"
            },
            {
                iD: 125,
                name: "ESS Super"
            },
            {
                iD: 126,
                name: "Finium"
            },
            {
                iD: 127,
                name: "First Super "
            },
            {
                iD: 128,
                name: "Freedom Of Choice "
            },
            {
                iD: 129,
                name: "GE Money"
            },
            {
                iD: 130,
                name: "Genesis Insurance"
            },
            {
                iD: 131,
                name: "GESB and West State Super"
            },
            {
                iD: 132,
                name: "Guild Super"
            },
            {
                iD: 133,
                name: "HCF "
            },
            {
                iD: 134,
                name: "HSBC"
            },
            {
                iD: 135,
                name: "IAG Life Insurance"
            },
            {
                iD: 136,
                name: "IBM Super"
            },
            {
                iD: 137,
                name: "Life Focus "
            },
            {
                iD: 138,
                name: "Lutheran Super"
            },
            {
                iD: 139,
                name: "Media Super"
            },
            {
                iD: 140,
                name: "Medibank"
            },
            {
                iD: 141,
                name: "Mercantile Mutual "
            },
            {
                iD: 142,
                name: "Military Super"
            },
            {
                iD: 143,
                name: "MyLife MySuper"
            },
            {
                iD: 144,
                name: "NESS"
            },
            {
                iD: 145,
                name: "NGS Superannuation "
            },
            {
                iD: 146,
                name: "NRMA"
            },
            {
                iD: 147,
                name: "Other"
            },
            {
                iD: 148,
                name: "Perpetual Investment Management Ltd"
            },
            {
                iD: 149,
                name: "Portfolio Solutions"
            },
            {
                iD: 150,
                name: "PrefSure Life Limited"
            },
            {
                iD: 151,
                name: "Primary Production Super"
            },
            {
                iD: 152,
                name: "Prudential"
            },
            {
                iD: 153,
                name: "Prudential Australia"
            },
            {
                iD: 154,
                name: "QBE"
            },
            {
                iD: 155,
                name: "QIEC Super"
            },
            {
                iD: 156,
                name: "Quadrant Super"
            },
            {
                iD: 157,
                name: "RACV Life"
            },
            {
                iD: 158,
                name: "REI Superannuation Fund Pty Ltd"
            },
            {
                iD: 159,
                name: "Rio Tinto Superannuation Fund "
            },
            {
                iD: 160,
                name: "Royal & SunAlliance"
            },
            {
                iD: 161,
                name: "Skandia"
            },
            {
                iD: 162,
                name: "Pinnacle"
            },
            {
                iD: 163,
                name: "Standard Life"
            },
            {
                iD: 164,
                name: "Super SA"
            },
            {
                iD: 165,
                name: "Telstra Superannuation Fund"
            },
            {
                iD: 166,
                name: "Transport Industry Super"
            },
            {
                iD: 167,
                name: "Ucover"
            },
            {
                iD: 168,
                name: "Victoria Police"
            },
            {
                iD: 169,
                name: "Vision Super "
            },
            {
                iD: 170,
                name: "VISSF Superannuation Fund"
            },
            {
                iD: 171,
                name: "WA Local Government Superannuation Fund"
            },
            {
                iD: 172,
                name: "Water Corporation Superannuation Fund"
            },
            {
                iD: 173,
                name: "Woolworths Insurance"
            },
            {
                iD: 174,
                name: "Accident & Health International"
            },
            {
                iD: 175,
                name: "Alcoa Super"
            },
            {
                iD: 176,
                name: "AMIST Super"
            },
            {
                iD: 177,
                name: "AustSafe Super"
            },
            {
                iD: 178,
                name: "BusQ"
            },
            {
                iD: 179,
                name: "EMPLUS Super"
            },
            {
                iD: 180,
                name: "Encircle Super"
            },
            {
                iD: 181,
                name: "Energy Super"
            },
            {
                iD: 182,
                name: "GIO Super"
            },
            {
                iD: 183,
                name: "Local Government Super"
            },
            {
                iD: 184,
                name: "Maritime Super"
            },
            {
                iD: 185,
                name: "Mercy Super"
            },
            {
                iD: 186,
                name: "National Mutual"
            },
            {
                iD: 187,
                name: "Nationwide Super"
            },
            {
                iD: 188,
                name: "Optimum Corporate Super"
            },
            {
                iD: 189,
                name: "Shell Australia"
            },
            {
                iD: 190,
                name: "Australian Retirement Trust - Super Savings"
            },
            {
                iD: 191,
                name: "Synergy Super"
            },
            {
                iD: 192,
                name: "NEOS"
            },
            {
                iD: 193,
                name: "Integrity Life"
            },
            {
                iD: 194,
                name: "Aware Super"
            },
            {
                iD: 195,
                name: "Aware Super - Police"
            },
            {
                iD: 196,
                name: "Aware Super - Ambulance"
            },
            {
                iD: 197,
                name: "Acclaim Super"
            },
            {
                iD: 198,
                name: "Australian Ethical Super"
            },
            {
                iD: 199,
                name: "AvSuper"
            },
            {
                iD: 200,
                name: "BUSSQ Super"
            },
            {
                iD: 201,
                name: "Commonwealth Group Super"
            },
            {
                iD: 202,
                name: "Encompass"
            },
            {
                iD: 203,
                name: "FirstChoice Personal Super"
            },
            {
                iD: 204,
                name: "FirstWrap LifeProtect Super"
            },
            {
                iD: 205,
                name: "Kogan Super"
            },
            {
                iD: 206,
                name: "Legal Super"
            },
            {
                iD: 207,
                name: "LGIA Brighter Super"
            },
            {
                iD: 208,
                name: "Active Super"
            },
            {
                iD: 209,
                name: "Zurich Life Active"
            },
            {
                iD: 210,
                name: "MLC Business Super"
            },
            {
                iD: 211,
                name: "MLC Super Fundamentals"
            },
            {
                iD: 212,
                name: "PPS Mutual"
            },
            {
                iD: 213,
                name: "Equip Corporate"
            },
            {
                iD: 214,
                name: "ANZ Smart Choice Super"
            },
            {
                iD: 215,
                name: "Suncorp Brighter Super"
            },
            {
                iD: 216,
                name: "Spirit Super"
            },
            {
                iD: 217,
                name: "Telstra Super Corporate"
            },
            {
                iD: 218,
                name: "Virgin Money Super"
            },
            {
                iD: 219,
                name: "Zurich Ezicover"
            },
            {
                iD: 220,
                name: "Tower Accelerated Protection"
            },
            {
                iD: 221,
                name: "TAL Accelerated Protection"
            },
            {
                iD: 222,
                name: "AC&L Living Security Program"
            },
            {
                iD: 223,
                name: "Australian Catholic Super"
            },
            {
                iD: 224,
                name: "AIG Priority Protection"
            },
            {
                iD: 225,
                name: "AIA Priority Protection"
            },
            {
                iD: 226,
                name: "AMG Personal Super"
            },
            {
                iD: 227,
                name: "AMP Risk Protection"
            },
            {
                iD: 228,
                name: "AMP Flexible Lifetime Protection"
            },
            {
                iD: 229,
                name: "ANZ Accident Cover Plus"
            },
            {
                iD: 230,
                name: "Asteron Lifeguard"
            },
            {
                iD: 231,
                name: "Asteron Life Complete"
            },
            {
                iD: 232,
                name: "Aviva Protectionfirst"
            },
            {
                iD: 233,
                name: "MLC Protectionfirst"
            },
            {
                iD: 234,
                name: "AXA Risk Protection Package"
            },
            {
                iD: 235,
                name: "AXA and AC&L Insurance"
            },
            {
                iD: 236,
                name: "AXA Elevate Insurance Solutions"
            },
            {
                iD: 237,
                name: "AMP Elevate"
            },
            {
                iD: 238,
                name: "AMP Elevate (Members only)"
            },
            {
                iD: 239,
                name: "Bar Cover"
            },
            {
                iD: 240,
                name: "Budget Direct Life Insurance"
            },
            {
                iD: 241,
                name: "Budget Direct Premium Life Direct"
            },
            {
                iD: 242,
                name: "Clearview LifeSolutions"
            },
            {
                iD: 243,
                name: "Clearview ClearChoice"
            },
            {
                iD: 244,
                name: "CommInsure Personal Insurance Portfolio"
            },
            {
                iD: 245,
                name: "EISS Super"
            },
            {
                iD: 246,
                name: "Encompass Protection"
            },
            {
                iD: 247,
                name: "Equip Super MyFuture"
            },
            {
                iD: 248,
                name: "Integrity Here For You"
            },
            {
                iD: 249,
                name: "ING OneCare"
            },
            {
                iD: 250,
                name: "OnePath OneCare"
            },
            {
                iD: 251,
                name: "Insuranceline"
            },
            {
                iD: 252,
                name: "LGIAsuper"
            },
            {
                iD: 253,
                name: "Macquarie Life FutureWise"
            },
            {
                iD: 254,
                name: "Zurich FutureWise"
            },
            {
                iD: 255,
                name: "Citicorp Simply Life and Your Life"
            },
            {
                iD: 256,
                name: "MetLife Simply Life and Your Life"
            },
            {
                iD: 257,
                name: "MLC Personal Protection Portfolio"
            },
            {
                iD: 258,
                name: "MLC Insurance"
            },
            {
                iD: 259,
                name: "MetLife Protect"
            },
            {
                iD: 260,
                name: "ANZ Mortgage Protection Insurance"
            },
            {
                iD: 261,
                name: "Mine Super"
            },
            {
                iD: 262,
                name: "NEOS Protection"
            },
            {
                iD: 263,
                name: "PPS Mutual Professionals Choice"
            },
            {
                iD: 264,
                name: "Qantas Super Voluntary Cover"
            },
            {
                iD: 265,
                name: "REI Super"
            },
            {
                iD: 266,
                name: "REST Super"
            },
            {
                iD: 267,
                name: "Equip Rio Tinto Super"
            },
            {
                iD: 268,
                name: "Equip Corporate Rio Tinto"
            },
            {
                iD: 269,
                name: "Russell Investments iQ Super For Life"
            },
            {
                iD: 270,
                name: "Sunsuper"
            },
            {
                iD: 271,
                name: "Zurich Sumo"
            },
            {
                iD: 272,
                name: "Tower Protection Policy"
            },
            {
                iD: 273,
                name: "BT Protection Plans"
            },
            {
                iD: 274,
                name: "Zurich Wealth Protection"
            },
            {
                iD: 275,
                name: "Australian Food Super"
            },
            {
                iD: 276,
                name: "HUB24 Super"
            },
            {
                iD: 277,
                name: "Mercer Business Super"
            },
            {
                iD: 278,
                name: "Mercer MyChoice Super"
            },
            {
                iD: 279,
                name: "PSSap super"
            },
            {
                iD: 280,
                name: "Vanguard Super SaveSmart"
            }];
}