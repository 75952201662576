export enum PaymentFrequencies {
    /** PaymentsFrequencys.Annually = 1 */
    Annually = 1,

    /** PaymentsFrequencys.BiAnnually = 2 */
    BiAnnually = 2,

    /** PaymentsFrequencys.Quarterly = 4 */
    Quarterly = 4,

    /** PaymentsFrequencys.Monthly = 12 */
    Monthly = 12,

    /** PaymentsFrequencys.TwiceMonthly = 24 */
    TwiceMonthly = 24,

    /** PaymentsFrequencys.Fortnightly = 26 */
    Fortnightly = 26,

    /** PaymentsFrequencys.Weekly = 52 */
    Weekly = 52,
}
