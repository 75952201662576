
import { Injectable, signal } from '@angular/core';
import { MasterDataItemString, MasterDataService } from './master-data.service';
import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { Client } from '../entities/household';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { JourneyClient } from '../entities/journey-client';
import { NameValue } from '../entities/name-value';
import { OwnerType } from '../entities/owner-type';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class JourneyClientService {
  constructor(
    private http: HttpClient,
    private householdService: HouseholdService
  ) { }

  primaryJourneyClient = signal<JourneyClient | null>(null);
  spouseJourneyClient = signal<JourneyClient | null>(null);

  getJourneyClient(journeyClientID: string): Observable<JourneyClient | null> {
    return this.http.get<JourneyClient | null>(`/api/journeyclients/${journeyClientID}/latest`);
  }

  saveJourneyClient(journeyClient: JourneyClient): Observable<JourneyClient> {
    return this.http.post<JourneyClient>("/api/journeyclients/", journeyClient);
  }

  async refresh(journeyID: string): Promise<void> {
    if (!this.householdService.household) return;

    this.primaryJourneyClient.set(await this.refreshJourneyClient(this.householdService.household.client, journeyID, (this.householdService.household.adviserNode ?? "Unknown")));

    if (this.householdService.household.spouse) {
      this.spouseJourneyClient.set(await this.refreshJourneyClient(this.householdService.household.spouse, journeyID, (this.householdService.household.adviserNode ?? "Unknown")));
    } else {
      this.spouseJourneyClient.set(null);
    }
  }

  createJourneyClient(client: Client, journeyID?: string): JourneyClient {
    return {
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      adviserNode: this.householdService.household?.adviserNode ?? "Unknown",
      firstName: client.firstName,
      lastName: client.lastName,
      journeyID,
      journeyClientID: client.clientID,
      birthDate: client.birthDate,
      gender: client.gender,
      salutationID: client.salutationID,
      maritalStatusID: this.householdService.household?.maritalStatusID,
      maritalStatus: this.householdService.household?.maritalStatusID ? MasterDataService.getNameForId(this.householdService.household.maritalStatusID, (this.householdService.household.spouse ? MasterDataService.getJointMaritalStatues(): MasterDataService.getMaritalStatues())) : "",
      middleName: client.middleName,
      preferredName: client.preferredName,
      residencyStatus: client.residencyStatusID ? MasterDataService.getNameForId(client.residencyStatusID, MasterDataService.getResidentialStatues()) : "",
      residencyStatusID: client.residencyStatusID,
      retirementAge: client.retirementAge,
      salutation: MasterDataService.getNameForId(client.salutationID, MasterDataService.getSalutations()),
      salutationOther: client.salutationOtherText
    }
  }

  getOwnerDropdownName(owner: OwnerType): string {
    const data = this.getOwnerDropdownOptions()
      .find(x => x.iD === owner);

    if (typeof data === "undefined") {
      return `<Owner name for ${String(owner)} could not be determined>.`
    }

    return data.name;
  }

  getOwnerDropdownOptions(): MasterDataItemString<OwnerType>[] {
    const data: MasterDataItemString<OwnerType>[] = [];

    data.push({ iD: "primary", name: this.getClientName("primary") })
    if (this.spouseJourneyClient()) {
      data.push({ iD: "spouse", name: this.getClientName("spouse") })
    }
    data.push({ iD: "joint", name: "Joint" });
    return data;
  }

  getClientName(owner: OwnerType, includeLastName = false): string {
    const primary = this.getNonNullablePrimary();
    const spouse = this.spouseJourneyClient();

    const primaryName = JourneyClientService.getClientNameCore(primary, includeLastName);
    const spouseName = JourneyClientService.getClientNameCore(spouse, includeLastName);

    switch (owner) {
      case "primary":
        return primaryName;
      case "spouse":
        return spouseName;
      case "joint":
        if (spouse) {
          if (spouse.lastName === primary.lastName && includeLastName) {
            return `${primary.firstName} & ${spouse.firstName} ${primary.lastName}`;
          }
          return `${primaryName} & ${spouseName}`;
        }
        return primaryName;
      default:
        throw new Error(`owner has an invalid value ${String(owner)}`)
    }
  }

  async loadJourneyClients(journeyID: string): Promise<void> {
    if (!this.householdService.household) return;

    const now = moment().utc().toDate();
    const primary = await firstValueFrom(this.getJourneyClient(this.householdService.household.client.clientID));

    if (primary) {
      primary.created = now;
      primary.lastModified = now;
      this.primaryJourneyClient.set(primary);

      await firstValueFrom(this.saveJourneyClient(primary));
    } else {
      this.primaryJourneyClient.set(await this.refreshJourneyClient(this.householdService.household.client, journeyID, (this.householdService.household.adviserNode ?? "Unknown")));
    }

    if (this.householdService.household.spouse) {
      const spouse = await firstValueFrom(this.getJourneyClient(this.householdService.household.spouse.clientID));

      if (spouse) {
        spouse.created = now;
        spouse.lastModified = now;
        this.spouseJourneyClient.set(spouse);
        await firstValueFrom(this.saveJourneyClient(spouse));
      } else {
        this.spouseJourneyClient.set(await this.refreshJourneyClient(this.householdService.household.spouse, journeyID, (this.householdService.household.adviserNode ?? "Unknown")));
      }
    } else {
      this.spouseJourneyClient.set(null);
    }
  }

  private static getClientNameCore(journeyClient: JourneyClient | null, includeLastName = false) {
    return `${journeyClient?.firstName} ${includeLastName ? journeyClient?.lastName : ""}`.trim();
  }

  private async refreshJourneyClient(client: Client, journeyID: string, adviserNode: string): Promise<JourneyClient> {
    let journeyClient = await firstValueFrom(this.getJourneyClient(client.clientID));

    if (journeyClient) {
      journeyClient.lastModified = moment().utc().toDate();
      journeyClient.adviserNode = adviserNode;
      journeyClient.firstName = client.firstName;
      journeyClient.lastName = client.lastName;
      journeyClient.birthDate = client.birthDate;
      journeyClient.gender = client.gender;
      journeyClient.middleName = client.middleName;
      journeyClient.preferredName = client.preferredName;
      journeyClient.retirementAge = client.retirementAge;
      JourneyClientService.refreshSalutations(journeyClient, client);
      JourneyClientService.refreshResidencyStatus(journeyClient, client);
      this.refreshMaritalStatus(journeyClient);
    } else {
      journeyClient = this.createJourneyClient(client, journeyID);
    }

    await firstValueFrom(this.saveJourneyClient(journeyClient));

    return journeyClient;
  }

  private static refreshSalutations(journeyClient: JourneyClient, client: Client) {
    journeyClient.salutation = MasterDataService.getSalutations().find(x => x.iD === client.salutationID)?.name;
    journeyClient.salutationOther = client.salutationOtherText;
    journeyClient.salutationID = client.salutationID;
  }

  private static refreshResidencyStatus(journeyClient: JourneyClient, client: Client) {
    journeyClient.residencyStatus = MasterDataService.getResidentialStatues().find(x => x.iD === client.residencyStatusID)?.name;
    journeyClient.residencyStatusID = client.residencyStatusID;
  }

  private refreshMaritalStatus(journeyClient: JourneyClient): void {
    journeyClient.maritalStatusID = this.householdService.household?.maritalStatusID;
    journeyClient.maritalStatus = this.householdService.household?.spouse ? MasterDataService.getJointMaritalStatues().find(x => x.iD === this.householdService.household?.maritalStatusID)?.name :
                       MasterDataService.getMaritalStatues().find(x => x.iD === this.householdService.household?.maritalStatusID)?.name;
  }

  getNonNullablePrimary(): JourneyClient {
    const primary = this.primaryJourneyClient();
    if (!primary) {
      throw new Error("The primary journeyClient has not been initialised.");
    }

    return primary;
  }

  getNonNullablePrimaryClientID(): string {
    const journeyClientID = this.primaryJourneyClient()?.journeyClientID;
    if (!journeyClientID) {
      throw new Error("The primary journeyClient has not been initialised.");
    }

    return journeyClientID;
  }

  getNonNullableSpouseClientID(): string {
    const journeyClientID = this.spouseJourneyClient()?.journeyClientID;
    if (!journeyClientID) {
      throw new Error("The primary journeyClient has not been initialised.");
    }

    return journeyClientID;
  }

  getPdfSection(): PdfSection {
    const pdfSection: PdfSection = {
      breakLine: true,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Personal",
      content: {
        tableHeaders: [
          {
            name: "Field",
            width: "30%"
          },
          {
            name: this.getNonNullablePrimary().firstName ?? "Primary",
            width: this.spouseJourneyClient() ? "30%" : "60%"
          }
        ],
        tableRows: []
      }
    };

    if (this.spouseJourneyClient()) {
      (pdfSection.content as TableContent).tableHeaders.push(
        {
          name: this.spouseJourneyClient()?.firstName ?? "Spouse",
          width: "30%"
        }
      );
    }

    const nameValues = this.getClientFields();

    (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x, (this.spouseJourneyClient() ? true : false))));

    return pdfSection;
  }

  private getClientFields(): NameValue[] {
    return [
      {
        name: "Salutation",
        value: this.getNonNullablePrimary().salutationOther ?? (this.getNonNullablePrimary().salutation ?? "-"),
        spouseValue: this.spouseJourneyClient()?.salutationOther ?? (this.spouseJourneyClient()?.salutation ?? "-"),
      },
      {
        name: "First name",
        value: this.getNonNullablePrimary().firstName,
        spouseValue: this.spouseJourneyClient()?.firstName
      },
      {
        name: "Middle name",
        value: this.getNonNullablePrimary().middleName,
        spouseValue: this.spouseJourneyClient()?.middleName
      },
      {
        name: "Last name",
        value: this.getNonNullablePrimary().lastName,
        spouseValue: this.spouseJourneyClient()?.lastName
      },
      {
        name: "Preferred name",
        value: this.getNonNullablePrimary().preferredName,
        spouseValue: this.spouseJourneyClient()?.preferredName
      },
      {
        name: "Gender",
        value: this.getNonNullablePrimary().gender,
        spouseValue: this.spouseJourneyClient()?.gender
      },
      {
        name: "Date of birth",
        value: moment(this.getNonNullablePrimary().birthDate).format("DD/MM/yyyy"),
        spouseValue: this.spouseJourneyClient() ? moment(this.spouseJourneyClient()?.birthDate).format("DD/MM/yyyy") : null
      },
      {
        name: "Retirement age",
        value: String(this.getNonNullablePrimary().retirementAge),
        spouseValue: String(this.spouseJourneyClient()?.retirementAge)
      },
      {
        name: "Residency status",
        value: this.getNonNullablePrimary().residencyStatus,
        spouseValue: this.spouseJourneyClient()?.residencyStatus
      },
      {
        name: "Marital status",
        value: this.getNonNullablePrimary().maritalStatus,
        spouseValue: this.spouseJourneyClient()?.maritalStatus
      }
    ];
  }
}