export enum AssetType {
    CashAccount = 1,
    ResidentialProperty = 2,
    AustralianShares = 3,
    InternationalShares = 4,
    ManagedFund = 5,
    CashTermDeposit = 6,
    InvestmentProperty = 7,
    Other = 8,
    // Holding does not exist in AOS, but we need to display is as an option in the dropdown,
    // so we assign it the below number. This number should not conflict with any existing AssetType.
    // This option, if selected, will not be saved back to AOS, it's a UI only thing.
    Holding = 9007199254740
}